import React, { useRef, useState } from 'react';
import Modal from '../Modal';

import { FaChartLine } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";

const InsertLineChartButton = ({ editorRef }) => {
  const modalRef = useRef();

  const openModal = () => {
    modalRef.current.open();
  };

  const closeModal = () => {
    modalRef.current.close();
  };

  const [formData, setFormData] = useState({
    title: '',
    xAxis: '',
    yAxis: '',
    data: [],
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDataChange = (index, event) => {
    const { name, value } = event.target;
    const newData = [...formData.data];
    newData[index][name] = value;
    setFormData(prevState => ({
      ...prevState,
      data: newData
    }));
  };

  const addDataField = () => {
    setFormData(prevState => ({
      ...prevState,
      data: [...prevState.data, { x: '', y: '' }]
    }));

    setTimeout(() => {
      const el = document.getElementById(`x-${formData.data.length}`);
      if (el) {
        el.focus();
      }
    }, 0);
  };

  const removeDataField = (index) => {
    const newData = formData.data.filter((_, i) => i !== index);
    setFormData(prevState => ({
      ...prevState,
      data: newData
    }));
  };

  const insertLineChart = () => {
    const editorInstance = editorRef.current.getInstance();
    const data = formData.data.map(item => `{ "x": ${item.x}, "y": ${item.y} }`).join(', ');
    let example = `<LineChart data={[${data}]} title="${formData.title}" yAxis="${formData.yAxis}" xAxis="${formData.xAxis}" />`


    if (editorInstance.isMarkdownMode()) {
      example = '\\${example}'
    }
    editorInstance.insertText(`\n${example}\n`);
    closeModal();
  };


  return (
    <div id="line-chart-button-container">
      <button type="button" onClick={openModal} className="tw-bg-transparent tw-border-0">
        <FaChartLine className="tw-text-goa tw-h-[25px] tw-w-[25px]" />
      </button>
      <Modal ref={modalRef} buttonText="Insert Line Chart" callback={insertLineChart}>
        <h2 className="tw-text-3xl tw-font-semibold tw-leading-7 tw-text-gray-900">Insert Line Chart</h2>


        <div className="tw-border-b tw-border-gray-900/10 tw-pb-12">
          <div className="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-4">

            <div className="sm:tw-col-span-4">
              <label htmlFor="title"
                className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3"
              >Title</label>
              <div className="tw-mt-2">
                <input id="title" name="title" type="text"
                  className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300"
                  value={formData.title}
                  onChange={handleFormChange}
                  placeholder="Gold Medals won in Olympics by UK"
                />
              </div>
            </div>

            <div className="sm:tw-col-span-4">
              <label htmlFor="xAxis"
                className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3"
              >xAxis Label</label>
              <div className="tw-mt-2">
                <input id="xAxis" name="xAxis" type="text"
                  className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md  tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300"
                  value={formData.xAxis}
                  onChange={handleFormChange}
                  placeholder="Years"
                />
              </div>
            </div>

            <div className="sm:tw-col-span-4">
              <label htmlFor="yAxis"
                className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3"
              >yAxis Label</label>
              <div className="tw-mt-2">
                <input id="yAxis" name="yAxis" type="text"
                  className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md  tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300"
                  value={formData.yAxis}
                  onChange={handleFormChange}
                  placeholder="Gold Medals"
                />
              </div>
            </div>



            {formData.data.map((item, index) => (
              <div key={index} className="sm:tw-col-span-4 tw-grid tw-grid-cols-1 sm:tw-grid-cols-5 tw-gap-x-6 tw-gap-y-8">
                <div className="sm:tw-col-span-2">
                  <label htmlFor={`x-${index}`} className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3">X Value</label>
                  <div className="tw-mt-2">
                    <input id={`x-${index}`} name="x" type="text" className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md  tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300" value={item.x} onChange={(e) => handleDataChange(index, e)}
                      placeholder="2020"
                    />
                  </div>
                </div>
                <div className="sm:tw-col-span-2">
                  <label htmlFor={`y-${index}`} className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3">Y Value</label>
                  <div className="tw-mt-2">
                    <input id={`y-${index}`} name="y" type="text" className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300" value={item.y} onChange={(e) => handleDataChange(index, e)}
                      placeholder="10"
                    />
                  </div>
                </div>
                <div className="tw-col-span-4 sm:tw-col-span-1 tw-flex tw-justify-center tw-items-end">
                  <button type="button" className="tw-mt-2 tw-text-red-500 tw-bg-transparent tw-border-0" onClick={() => removeDataField(index)}>
                    <MdDeleteForever className="tw-text-red-300 tw-h-12 tw-w-12" />
                  </button>
                </div>
              </div>
            ))}
            <div className="sm:tw-col-span-4">
              <button type="button" className="tw-mt-2 tw-p-2 tw-bg-goa tw-text-white tw-rounded-md tw-px-3 tw-py-2 tw-text-3xl tw-font-semibold tw-shadow-sm tw-border-0" onClick={addDataField}>Add Data</button>
            </div>


          </div>

        </div>
      </Modal>
    </div>
  );
};

export default InsertLineChartButton;
